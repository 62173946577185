<template>
  <el-row class="center-row" style="margin:20px 0 20px 0">
    <el-col :xs="22" :sm="18" :md="18" :lg="16" :xl="16">
      <el-row class="center-row" style="background: rgba(255,255,255,0.6);margin-top: 60px;margin-bottom: 70px;">
        <tr>
          <td
            style="vertical-align: top; color:#ffc62d; font-size:36px; font-family:'Times New Roman',serif; font-weight:bold; text-align:left; padding:10px 10px; line-height:100%">
            <span>“</span>
          </td>
          <td
            style="font-family: 'Jiexing'; font-size: 30px; color: #ffc62d; font-weight: bold;text-align: left; padding: 1em; vertical-align: middle;">
            <span>{{ title }}</span>
          </td>
          <td
            style="vertical-align: bottom; color:#ffc62d; font-size:36px; font-family:'Times New Roman',serif; font-weight:bold; text-align:left; padding:10px 10px; line-height:100%">
            <span>”</span>
          </td>
        </tr>
      </el-row>
      <el-row id="intro" justify="start" align="bottom" style="margin: 0 0 20px 0">
        <el-image class="h1-icon" src="icon/intro.webp"></el-image>
        <div class="h1">简介</div>
      </el-row>
      <div v-html="intro" class="text">
      </div>

      <el-divider border-style="dashed"></el-divider>

      <el-row id="video" justify="start" align="bottom" style="margin: 0 0 20px 0">
        <el-image class="h1-icon" src="icon/video.webp"></el-image>
        <div class="h1">视频</div>
      </el-row>

      <div style="display: flex; flex-wrap: wrap;">
        <div class="video-small hidden-md-and-up" v-for="(video, index) in videoList" :key="index">
          <el-link :underline="false" :href="video.url" target="_blank">
            <el-image class="video-cover-small" :src="video.pic" loading="lazy" />
          </el-link>
          <el-link class="video-title" :underline="false" :href="video.url" target="_blank">{{ video.title }}</el-link>
        </div>
        <div class="video-large hidden-sm-and-down" v-for="(video, index) in videoList" :key="index">
          <el-link :underline="false" :href="video.url" target="_blank">
            <el-image class="video-cover-large" :src="video.pic" />
          </el-link>
          <el-link class="video-title" :underline="false" :href="video.url" target="_blank">{{ video.title }}</el-link>
        </div>
      </div>

      <el-divider border-style="dashed"></el-divider>

      <el-row id="image" justify="start" align="bottom" style="margin: 0 0 20px 0">
        <el-image class="h1-icon" src="icon/image.webp"></el-image>
        <div class="h1">图片</div>
      </el-row>

      <el-carousel :autoplay="false">
        <el-carousel-item v-for="item in photoList" :key="item">
          <el-image style="height:100%" fit="contain" :src="item" loading="lazy"></el-image>
        </el-carousel-item>
      </el-carousel>
      <el-divider border-style="dashed"></el-divider>

      <el-row id="qa" justify="start" align="bottom" style="margin: 0 0 20px 0">
        <el-image class="h1-icon" src="icon/qa.webp"></el-image>
        <div class="h1">Q&A</div>
      </el-row>

      <el-collapse v-model="activeNames" @change="handleChange" class="text">
        <el-collapse-item v-for="(qa, index) in qaList" :key="index" :title="qa.q" :name="index">
          <div v-html="qa.a"></div>
        </el-collapse-item>
      </el-collapse>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      title: '初次见面。随花信风一同来访，我是花之精灵。蛍川。',
      intro: '<span>' + '散漫自在得有些过头的幼年精灵。本体是戴在头上的，以向日葵为主体的花环。' + '<br/>' +
        '出于好奇，使用特定的通讯设备从自己所在的世界「此方」，与屏幕前「彼方」的人类进行着接触。' + '<br/>' +
        '购置通讯设备时看少了一个零从而欠下了巨额债务，目前在「此方」的人类社会，某个边境小镇隐藏着精灵身份打工还债中。' + '<br/>' +
        '作为以花为本体的精灵， 自身周围的植物花期会比平时更久一些。 考虑到这一点选择了花店的工作。' + '</span>',
      videoList: [{ pic: 'cover/011eab09d75b4e668dcde2a8a6a51eaa258a012f.jpg@320w_200h_1c_!web-space-upload-video.webp', title: 'フロイデ / 蛍川（PV附）', url: 'https://www.bilibili.com/video/BV1jU411o7fU' },
      { pic: 'cover/50648231bbe6ccfa15f772c45fc8981eb423dad2.jpg@320w_200h_1c_!web-space-upload-video.webp', title: '【maimai翻唱】𝑴𝒂𝒈𝒊𝒄𝒂𝒍 𝑭𝒍𝒂𝒗𝒐𝒓 / 蛍川', url: 'https://www.bilibili.com/video/BV1cc411c7Nx' },
      { pic: 'cover/b1ccb7cfead778121a90e85c5fb6bf78ddb82232.jpg@320w_200h_1c_!web-space-upload-video.webp', title: 'スイセンと酸性雨 / 蛍川', url: 'https://www.bilibili.com/video/BV1YN411m7eo' },
      { pic: 'cover/f92fc199406f34ad2ff4b1fb4814480cadcd405b.jpg@320w_200h_1c_!web-space-upload-video.webp', title: '⭐再过三秒就对上视线！🌻奶油苏打与香槟汽水/クリームソーダとシャンデリア【原创PV付】', url: 'https://www.bilibili.com/video/BV1De4y1P7Kb' },
      { pic: 'cover/1e41cac8159b085d1992ede1c68f315e08ad76f6.jpg@320w_200h_1c_!web-space-upload-video.webp', title: '惑星ループ / 蛍川', url: 'https://www.bilibili.com/video/BV1UK411C7zw' },
      { pic: 'cover/eb1f365a2733e60b489a4863ca951a14acf9983e.jpg@320w_200h_1c_!web-space-upload-video.webp', title: '✨你的夏日特效药✨颗粒冰沙感 | 𝑹𝒖𝒎𝒐𝒓', url: 'https://www.bilibili.com/video/BV1VB4y1b7R5' },
      { pic: 'cover/cfb07794b7be6cacb18bdfa31757184307c4e793.jpg@320w_200h_1c_!web-space-upload-video.webp', title: 'Chocolate Milk / 蛍川', url: 'https://www.bilibili.com/video/BV1LL411K7DS' },],
      photoList: ['image/1.jpg', 'image/2.jpg', 'image/3.jpg', 'image/4.jpg', 'image/5.jpg'],
      qaList: [{ q: '平时什么时候直播？会有日程表吗？', a: '<span>直播时间相对来讲比较自由，不会准备日程表。比较重要的直播会在B站提前发布预约，每日的直播安排会在下午放出，除此之外就是突击直播啦。可以特别关注或加入直播通知群获取一手消息！</span>' },
      { q: '为什么会当虚拟主播呢？出道之后自己身上有发生变化吗？', a: '<span>起初是会唱一点点歌因此被朋友建议加入身边的虚拟主播社团。后来没有成功留在社团里，但在此期间D到了很多很多有趣的虚拟主播，最后还是选择了以个花势身份出道！出道之后不擅长与人类沟通的毛病改善了不少，同时也爱上了学习未曾涉足领域的知识，点亮技能树的感觉！从一窍不通到能拿出完全属于自己的成品的感觉真的很快乐-v-</span>' },
      { q: '川的名字是怎么来的呢？', a: '<span>还在精灵之森的时候夜里不会有城镇的灯光，唯一能照亮周遭的就是天上的星海与林间的萤火虫。如果说天上闪烁的星星组成的是银河，那么在我们之间闪着微光的大概就是萤光组成的溪水对吧？——总之是被人类第一次问起的时候这么想着，灵光乍现的名字。</span>' },
      { q: '我对川川的活动有一些建议or意见，请问要用什么方式提出比较好呢？', a: '<span>B站的私信（实名）与网站的<a class="external_text" target="_blank" href="https://candy.vupgo.com/330525028">提问箱</a>（匿名）都是可以收到大家的建议与意见的！大家可以选择自己喜欢的方式提出建议or表达意见。当然精灵的活动目的还是以让自己跟各位玩得尽兴为主，对活动事业操之过急的话可能会失望哦！</span>' },
      { q: '虚拟主播生涯有什么目标吗？', a: '<span>与大家一起玩得尽兴就好！希望大家可以通过我认识更多有趣的虚拟主播，哪天要是累了也可以回到花田里休息一下。虽然很没有追求（私密马赛）但也是目前最想做的事情了！</span>' }]
    }
  },
  methods: {
  }
}
</script>

<style>
.el-divider {
  border-top: 1px var(--el-color-primary-light-3) var(--el-border-style);
}

.el-divider__text {
  color: var(--el-color-primary-light-3);
  background-color: transparent;
}

.h1-icon {
  height: 70px;
  width: 70px;
}

.h1 {
  font-size: 36px;
  font-family: 'Maoken';
  color: #333333;
}

.text {
  font-family: 'CangerYuyang';
  font-size: 18px;
  color: #333333;
  line-height: 2;
  overflow-wrap: break-word;
}

.video-small {
  width: 160px;
  text-align: center;
  margin: 5px;
}

.video-large {
  width: 200px;
  text-align: center;
  margin: 10px;
}

.video-cover-small {
  width: 160px;
  height: 100px;
  border-radius: 4px;
}

.video-cover-large {
  width: 200px;
  height: 125px;
  border-radius: 4px;
}

.video-title {
  padding: 5px 10px;
  border-radius: 4px;
}

.el-carousel__container {
  height: 300px;
  background-color: #ffffff66;
}

.el-carousel__item {
  align-items: center;
  justify-content: center;
  display: flex;
}

.el-collapse {
  --el-collapse-header-font-size: 18px;
  --el-collapse-header-text-color: #333333;
  --el-collapse-content-font-size: 18px;
  --el-collapse-content-text-color: #333333;
  --el-collapse-border-color: var(--el-color-primary-light-3);
}

.el-collapse-item__header {
  font-weight: bold;
  height: auto;
  line-height: inherit;
  padding: 10px 0;
  border-color: var(--el-color-primary-light-3);
}

.external_text {
  color: #333333;
}
</style>