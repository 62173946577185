<template>
    <el-row class="center-row" style="margin:100px 0;min-height: 400px;">
        <el-col :xs="22" :sm="18" :md="18" :lg="16" :xl="16">
            <div class="fanart-title"><span>{{ title }}</span></div>
            <div v-for="(content, index) in contents" :key="index" class="fanart-text" v-html="content"></div>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "Fanart",
    data() {
        return {
            title: '二次创作守则',
            contents: ['<span>1．“蛍川”的官方美术资源已进行过版权登记。在未经授权的情况下，请勿将“蛍川”的官方稿件进行制品印刷、会员权益或用于非“蛍川”的其他宣传。</span>',
                '<span>2．使用“蛍川”IP自行绘制或约稿，策划的制品在明确标注IP来源的前提下可以盈利。无需额外申请授权，制品不会收取费用或分成。</span>',
                '<span>3．在注明来源的情况下，可以使用“蛍川”的声音进行AI声库的制作与相关创作，亦可将“蛍川”的音乐投稿作品进行AI翻唱参考。</span>',
                '<span>4．不可对“蛍川”的官方美术稿件与粉丝的二次创作进行AI处理，不可将其作为AI的学习参考。</span>',
                '<span>5．如二次创作作品违反上述原则，发生违法违规，对“蛍川”形象造成恶劣影响的，“蛍川”有权要求下架作品，并保留相关追诉责任。</span>']
        }
    },
    methods: {
    }
}
</script>

<style>
.fanart-title {
    font-family: 'CangerYuyang';
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
}

.fanart-text {
    font-family: 'CangerYuyang';
    font-size: 18px;
    color: #333333;
    line-height: 2;
    overflow-wrap: break-word;
    margin: 10px 0;
}
</style>